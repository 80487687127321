<script setup lang="ts">
    import type { BreadcrumbVisibility } from '~/@types/cms';

    interface BreadcrumbsProps {
        items: {
            title: string;
            slug: string;
        }[];
        visibility: BreadcrumbVisibility;
    }
    const props = defineProps<BreadcrumbsProps>();
    const config = useRuntimeConfig();
    useJsonld(() => {
        if (!props.items.length) return null;
        return {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: props.items.map((item, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': `${config.public.BASE_URL}${item.slug}`,
                    name: removeWoomTextFormat(item.title),
                },
            })),
        };
    });

    const isLastBreadcrumb = (index: number) => index + 1 === props.items.length;
</script>
<template>
    <div :class="['atm-breadcrumbs overflow-hidden', { 'max-md:hidden': visibility === 'none_mobile' }]">
        <ol
            v-if="Array.isArray(items) && items.length"
            v-dragscroll.x
            class="scrollbar flex h-14 items-center gap-2 overflow-x-auto font-mono text-sm">
            <!-- padding on the first and last list items for an enhanced content view during scrolling -->
            <li
                v-for="(item, index) in items"
                :key="index"
                class="flex items-center gap-2 first:pl-6 last:pr-6 first:md:pl-8 last:md:pr-8">
                <nuxt-link
                    :to="isLastBreadcrumb(index) ? '#main-content' : item.slug"
                    :aria-current="isLastBreadcrumb(index) ? 'page' : undefined"
                    :class="[{ 'text-woom-red hover:no-underline': isLastBreadcrumb(index) }, 'whitespace-nowrap hover:underline']">
                    <renderer-html :content="woomTextFormat(item.title)" />
                </nuxt-link>
                <woom-icon-chevron-right
                    v-if="!isLastBreadcrumb(index)"
                    width="14"
                    height="14" />
            </li>
        </ol>
    </div>
</template>
