import type { BreadcrumbVisibility } from '~/@types/cms';
import type { PageProps, ProductPageProps } from '~/@types/generic';
import { useSeo } from '~/composables/seo';

/**
 * ## Page Composable
 * Your one stop shop for all page relate things
 * - breadcrumbs
 * - page data
 * - reference helper
 * - SEO
 * - ...
 */
export const usePage = (props: PageProps | ProductPageProps) => {
    useSeo(props);
    const localePath = useLocalePathWoom();

    const page = computed(() => props.pageData?.page);

    type PageStateType = {
        configurator: CollectionQuery_Collection_Products_Item[] | undefined;
        promotion: PageByUrlQuery_PageByUrl_Page_Product_Promo | null; // UNKNOWN_TYPE
        bundleData: BundlesQuery_Bundles;
    };

    const pageState = useState<PageStateType>('product-page-renderer-state', () => ({
        configurator: [],
        promotion: null,
        bundleData: null,
    }));

    const breadcrumbItems = computed(() =>
        page.value?.breadcrumbs?.reduce((accumulator, currentValue) => {
            if (currentValue.breadcrumb && currentValue.url?.url) {
                accumulator.push({
                    title: currentValue.breadcrumb,
                    slug: localePath(currentValue.url.url),
                });
            }
            return accumulator;
        }, []),
    );

    const breadcrumbVisibility: BreadcrumbVisibility = (page.value?.breadcrumbVisibility as BreadcrumbVisibility) ?? 'none';

    const bundles = computed(() => {
        const b = page.value?.content?.bundles || '';
        if (b) {
            const ret = [];
            const s = b.split(',');
            for (const bu of s) {
                ret.push(bu.trim());
            }
            return ret;
        }
        return [];
    });

    const upsellData = computed(() => {
        // pass in data by reference to be more performant
        const data: UpsellDataType = [];
        if (pageState.value.bundleData && pageState.value.bundleData.length > 0) {
            for (const bundle of pageState.value.bundleData) {
                data.push({
                    display: 'bundle',
                    data: bundle,
                });
            }
        }
        if (pageState.value.configurator && pageState.value.configurator.length > 0) {
            for (const product of pageState.value.configurator) {
                data.push({
                    display: 'product',
                    data: product,
                });
            }
        }
        return data;
    });

    return {
        breadcrumbItems,
        breadcrumbVisibility,
        bundles,
        upsellData,
        page,
        pageState,
        pageId: computed(() => props.pageData?.id),
        isBike: computed(() => isProductBike(page.value?.product)),
    };
};
